import React, { useEffect, useState } from 'react'
import { NavWrapper } from '../nav.styled'
import Dropdown from '../../Dropdown'
import * as Styled from './styles'
import { theme } from 'theme/theme'
import { dropdownContent } from '../dropdownData'

const DesktopMenu = ({
  linksData,
  scrollToSectionHandler,
  black,
  location,
}) => {
  const [activeSection, setActiveSection] = useState('')
  const activeHeaderStyles = {
    backgroundColor: theme.colors.blue,
    display: 'flex',
    alignItems: 'center',
    height: '85px',
    marginTop: '-30px',
    marginBottom: '-30px',
    padding: '0 10px',
    color: 'white',
  }

  const isOfferPathname =
    location.pathname.includes('transport') ||
    location.pathname.includes('logistyka') ||
    location.pathname.includes('dostawa')

  useEffect(() => {
    if (window.location.pathname === '/') {
      const aboutUsSection = document.getElementById('about')
      const contactSection = document.getElementById('contact')
      const fleetSection = document.getElementById('fleet')
      const nav = document.getElementById('nav')

      const check = () => {
        const navHeight = nav.getBoundingClientRect().height
        const aboutHeight =
          aboutUsSection.getBoundingClientRect().height - navHeight
        const aboutTop = aboutUsSection.getBoundingClientRect().top
        const contactHeight =
          contactSection.getBoundingClientRect().height - navHeight
        const contactTop = contactSection.getBoundingClientRect().top
        const fleetHeight =
          fleetSection.getBoundingClientRect().height - navHeight
        const fleetTop = fleetSection.getBoundingClientRect().top

        switch (true) {
          case aboutTop <= navHeight && aboutTop > -aboutHeight: {
            return setActiveSection('o nas')
          }
          case contactTop <= navHeight && contactTop > -contactHeight: {
            return setActiveSection('kontakt')
          }
          case fleetTop <= navHeight && fleetTop > -fleetHeight: {
            return setActiveSection('nasza flota')
          }
          default: {
            setActiveSection('')
          }
        }
      }
      check()
      window.addEventListener('scroll', check)
    }
  }, [])

  return (
    <NavWrapper black={black}>
      <ul className="main-list">
        {linksData.map(({ link, name }) => {
          const handleNav = () => {
            if (name === 'o nas') {
              scrollToSectionHandler('about')
            } else if (name === 'kontakt') {
              scrollToSectionHandler('contact')
            } else if (name === 'nasza flota') {
              scrollToSectionHandler('fleet')
            } else {
              return
            }
          }

          if (name === 'oferta') {
            return (
              <li key={name}>
                <Dropdown
                  header={name}
                  headerStyles={isOfferPathname ? activeHeaderStyles : {}}
                  content={
                    <>
                      {dropdownContent.map(({ title, link }) => (
                        <Styled.DropdownLink
                          to={link}
                          active={
                            decodeURI(location.href).includes(link) ? 1 : 0
                          }
                          key={title}
                        >
                          {title}
                        </Styled.DropdownLink>
                      ))}
                    </>
                  }
                />
              </li>
            )
          }
          if (
            typeof window !== 'undefined' &&
            window.location.pathname === '/'
          ) {
            return (
              <li key={name}>
                <Styled.ButtonNav
                  onClick={handleNav}
                  active={name === activeSection}
                >
                  {name}
                </Styled.ButtonNav>
              </li>
            )
          } else {
            return (
              <li key={name}>
                <Styled.LinkMain to={link} onClick={handleNav}>
                  {name}
                </Styled.LinkMain>
              </li>
            )
          }
        })}
      </ul>
    </NavWrapper>
  )
}

export default DesktopMenu
