import React from 'react'
import Logo from 'src/components/Logo'
import { Link } from 'gatsby'
import { Wrapper } from './nav.styled'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'
import linksData from './nav.data'
import smoothscroll from 'smoothscroll-polyfill'

const Nav = ({ location, black }) => {
  const scrollToSectionHandler = (sectionName, offset = 0) => {
    const sectionElement = document.getElementById(sectionName)
    smoothscroll.polyfill()
    const defaultOffset = -document
      .getElementById('nav')
      .getBoundingClientRect().height
    if (location.pathname === '/' && sectionElement) {
      const yOffset = defaultOffset + offset
      const y =
        sectionElement.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset

      window.scrollTo({ top: y + 5, behavior: 'smooth' })
    } else {
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionName)
        const yOffset = defaultOffset + offset
        const y =
          sectionElement?.getBoundingClientRect()?.top +
          window?.pageYOffset +
          yOffset

        window.scrollTo({ top: y + 5 })
      }, 10)
    }
  }

  return (
    <Wrapper black={black} id="nav">
      <Link to="/" style={{ display: 'inline-block', objectFit: 'contain' }}>
        <Logo />
      </Link>
      <DesktopMenu
        linksData={linksData}
        scrollToSectionHandler={scrollToSectionHandler}
        black={black}
        location={location}
      />
      <MobileMenu
        linksData={linksData}
        scrollToSectionHandler={scrollToSectionHandler}
      />
    </Wrapper>
  )
}

export default Nav
