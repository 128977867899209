import React from 'react'
import * as Styled from './styles'
const Burger = ({ setIsOpen, isOpen }) => {
  const handleClick = () => {
    if (!isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    setIsOpen(value => !value)
  }
  return (
    <Styled.Button onClick={handleClick} isOpen={isOpen}>
      <span />
      <span />
      <span />
    </Styled.Button>
  )
}

export default Burger
