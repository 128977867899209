const data = [
  {
    name: 'oferta',
  },
  {
    name: 'nasza flota',
    link: '/',
  },
  {
    name: 'o nas',
    link: '/',
  },
  {
    name: 'kontakt',
    link: '/',
  },
]

export default data
