import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 12px;
    margin-right: 15px;
  }

  button {
    cursor: pointer;
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.colors.blue};
    font-size: 12px;
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.white};
  }
`
