import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef } from 'react'
import { Button } from './styles'
import smoothscroll from 'smoothscroll-polyfill'

const UpButton = () => {
  const upButtonRef = useRef(null)
  const handleClick = () => {
    smoothscroll.polyfill()

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const showUpButton = useCallback(() => {
    if (window.scrollY >= 400) {
      upButtonRef.current.style.display = 'block'

      setTimeout(() => {
        upButtonRef.current.style.opacity = 1
      }, 200)
    } else {
      upButtonRef.current.style.opacity = 0
      upButtonRef.current.style.display = 'none'
    }
  }, [])
  useEffect(() => {
    window.addEventListener('scroll', showUpButton)

    return () => {
      window.removeEventListener('scroll', showUpButton)
    }
  }, [showUpButton])
  return (
    <Button onClick={handleClick} ref={upButtonRef}>
      <FontAwesomeIcon icon={faLongArrowAltUp} />
    </Button>
  )
}

export default UpButton
