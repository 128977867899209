import React, { useContext } from 'react'
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'contexts/GlobalContextProvider'
import * as Styled from './styles'

const CookiesBar = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const { isCookieBarVisible } = state

  const handleHideCookies = () => {
    localStorage.setItem('cookies', false)
    dispatch({
      type: 'SET_COOKIES_BAR_VISIBLE',
      value: false,
    })
  }

  return isCookieBarVisible ? (
    <Styled.Wrapper>
      <p>
        Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
        poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich
        użycie.
      </p>
      <button onClick={handleHideCookies}>Zgoda</button>
    </Styled.Wrapper>
  ) : null
}

export default CookiesBar
