import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme } from 'src/theme/theme'
import Footer from '../../components/Footer'
import UpButton from 'components/UpButton'
import CookiesBar from '../../components/CookiesBar'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: black;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  ul {
      list-style: none;
  }

  a{
      text-decoration: none;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
  }
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <main>{children}</main>
      <UpButton />
      <CookiesBar />
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
