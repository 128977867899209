import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  position: relative;
  padding: 70px 50px;
  @media only screen and (max-width: 1023px) {
    padding: 70px 20px;
  }

  .icons {
    font-size: 10px;
    color: #616161;
    a {
      color: #616161 !important;
      font-size: 10px !important;
    }
  }
  .image-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: -1;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    max-width: 1400px;
    margin: 0 auto;
    z-index: 1;
    .block {
      margin-bottom: 30px;
      p {
        color: ${({ theme }) => theme.colors.white};
        font-size: 20px;
        @media only screen and (max-width: 1023px) {
          font-size: 16px;
        }
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.white};
      margin: 60px 0 10px;
    }
    .bottom {
      color: ${({ theme }) => theme.colors.white};
      @media only screen and (max-width: 1023px) {
        font-size: 14px;
      }
      a {
        color: ${({ theme }) => theme.colors.white};
        @media only screen and (max-width: 1023px) {
          font-size: 14px;
        }
      }
    }
  }
`
