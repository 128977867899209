import styled from 'styled-components'

export const Button = styled.button`
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'relative')};
  top: ${({ isOpen }) => (isOpen ? '15px' : '')};
  right: ${({ isOpen }) => (isOpen ? '20px' : '')};
  z-index: 2;
  width: 25px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto;
  span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transform: translateY(0) rotate(0);
    opacity: 1;
  }

  span:nth-child(1) {
    animation: ${({ isOpen }) => (isOpen ? 'burgerTop 0.5s forwards' : 'none')};
    @keyframes burgerTop {
      0% {
        transform: translateY(0) rotate(0);
      }
      50% {
        transform: translateY(9px) rotate(0);
      }
      100% {
        transform: translateY(9px) rotate(-45deg);
      }
    }
  }
  span:nth-child(2) {
    animation: ${({ isOpen }) =>
      isOpen ? 'burger-middle 0.5s forwards' : 'none'};
  }
  span:nth-child(3) {
    animation: ${({ isOpen }) =>
      isOpen ? 'burger-bottom 0.5s forwards' : 'none'};
  }

  @keyframes burger-bottom {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-9px) rotate(0);
    }
    100% {
      transform: translateY(-9px) rotate(45deg);
    }
  }
  @keyframes burger-middle {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`
