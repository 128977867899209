import { Link } from 'gatsby'
import styled from 'styled-components'

export const DropdownLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  width: fit-content;
  white-space: nowrap;
  margin: 10px;
  cursor: pointer;
  padding: 10px 25px;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.blue : 'transparent'};
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.15s linear;

  &:hover {
    color: ${({ theme, active }) =>
      active ? `${theme.colors.white} !important` : theme.colors.blue};
  }
`
export const ButtonNav = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.white} !important;
  transition: all 0.15s linear;
  &:hover {
    color: ${({ theme }) => theme.colors.blue} !important;
  }
`
export const LinkMain = styled(Link)`
  cursor: pointer;
  text-transform: uppercase;
  color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.white} !important;
  transition: all 0.15s linear;
  &:hover {
    color: ${({ theme }) => theme.colors.blue} !important;
  }
`
