import React, { useState, useEffect } from 'react'
import Burger from './Burger'
import { Link } from 'gatsby'
import { useTrail, a } from 'react-spring'
import * as Styled from './styles'
import Logo from '../../Logo/logo'
import { dropdownContent } from '../dropdownData'

const Trail = ({ isOpen, children }) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    opacity: isOpen ? 1 : 0,
    x: isOpen ? 0 : 100,
    from: { opacity: 0, x: 100 },
    delay: 350,
    duration: 150,
  })
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <a.div key={index} style={style}>
          <a.div>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  )
}

const MobileMenu = ({ linksData, scrollToSectionHandler }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isExtendedMenuOpen, setIsExtendedMenuOpen] = useState(false)
  const [activeSection, setActiveSection] = useState('')

  useEffect(() => {
    if (window.location.pathname === '/') {
      const aboutUsSection = document.getElementById('about')
      const contactSection = document.getElementById('contact')
      const fleetSection = document.getElementById('fleet')
      const nav = document.getElementById('nav')

      const check = () => {
        const navHeight = nav.getBoundingClientRect().height
        const aboutHeight =
          aboutUsSection.getBoundingClientRect().height - navHeight
        const aboutTop = aboutUsSection.getBoundingClientRect().top
        const contactHeight =
          contactSection.getBoundingClientRect().height - navHeight
        const contactTop = contactSection.getBoundingClientRect().top
        const fleetHeight =
          fleetSection.getBoundingClientRect().height - navHeight
        const fleetTop = fleetSection.getBoundingClientRect().top

        switch (true) {
          case aboutTop <= navHeight && aboutTop >= -aboutHeight: {
            return setActiveSection('o nas')
          }
          case contactTop <= navHeight && contactTop >= -contactHeight: {
            return setActiveSection('kontakt')
          }
          case fleetTop <= navHeight && fleetTop >= -fleetHeight: {
            return setActiveSection('nasza flota')
          }
          default: {
            setActiveSection('')
          }
        }
      }
      check()
      window.addEventListener('scroll', check)
    }
  }, [])

  return (
    <Styled.Wrapper isOpen={isOpen}>
      <Burger setIsOpen={setIsOpen} isOpen={isOpen} />
      <ul>
        <Link
          onClick={() => setIsOpen(false)}
          to="/"
          style={{
            position: 'absolute',
            top: 40,
            display: isOpen ? 'block' : 'none',
          }}
        >
          <Logo />
        </Link>

        <Trail isOpen={isOpen}>
          {linksData.map(({ link, name }) => {
            const handleNav = () => {
              setIsExtendedMenuOpen(false)
              document.body.style.overflow = ''
              setIsOpen(false)

              if (name === 'o nas') {
                scrollToSectionHandler('about')
              } else if (name === 'kontakt') {
                scrollToSectionHandler('contact')
              } else if (name === 'nasza flota') {
                scrollToSectionHandler('fleet')
              } else {
                return
              }
            }

            return name !== 'oferta' ? (
              <li key={name}>
                <Styled.LinkMain
                  onClick={handleNav}
                  active={activeSection === name}
                >
                  {name}
                </Styled.LinkMain>
              </li>
            ) : (
              <>
                <Styled.ExtendedMenuButton
                  onClick={() => setIsExtendedMenuOpen(prevState => !prevState)}
                >
                  <p>{name}</p>
                  <span />
                </Styled.ExtendedMenuButton>

                <Styled.ExtendedOfferMenu
                  isExtendedMenuOpen={isExtendedMenuOpen}
                >
                  {dropdownContent.map(({ title, link }) => (
                    <div>
                      <Link
                        to={link}
                        onClick={handleNav}
                        activeClassName="active-link"
                      >
                        {title}
                      </Link>
                    </div>
                  ))}
                </Styled.ExtendedOfferMenu>
              </>
            )
          })}
        </Trail>
      </ul>
    </Styled.Wrapper>
  )
}

export default MobileMenu
