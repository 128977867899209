/* eslint-disable react/jsx-pascal-case */
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import SchemaOrg from './schema-org'
import config from '../../../config/website'
import defaultMetaImage from '../../../static/images/metaImage.jpg'
import favicon from '../../../static/images/favicon.png'

function SEO({
  siteMetadata: seo,
  title = config.siteTitle,
  description = seo.description,
  image = defaultMetaImage,
  url = seo.canonicalUrl,
}) {
  return (
    <>
      <Helmet
        link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
      >
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        url={url}
        title={title}
        image={image}
        description={description}
        canonicalUrl={seo.canonicalUrl}
        defaultTitle={seo.title}
      />
    </>
  )
}

function SEOWithQuery(props) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          canonicalUrl
          image
        }
      }
    }
  `)
  return <SEO siteMetadata={siteMetadata} {...props} />
}

export default SEOWithQuery
