import styled from 'styled-components'

export const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: #171717;
  z-index: 9999;
  @media only screen and (max-width: 1023px) {
    padding: 5px 20px 5px 10px;
  }
`

export const NavWrapper = styled.div`
  margin: 0 auto;
  max-width: 650px;
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
  }
  ul.main-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    font-weight: 700;
    .active {
      background-color: ${({ theme, black }) =>
        black ? theme.colors.blue : 'transparent'};
      display: ${({ black }) => (black ? 'flex' : 'block')};
      align-items: ${({ black }) => (black ? 'center' : '')};
      height: ${({ black }) => (black ? '150px' : 'auto')};
      margin-top: ${({ black }) => (black ? '-30px' : '0')};
      margin-bottom: ${({ black }) => (black ? '-30px' : '0')};
      padding: ${({ black }) => (black ? '0 10px' : '0')};

      &:hover {
        color: ${({ theme, black }) =>
          black ? theme.colors.white : theme.colors.blue};
      }
    }
    li {
      margin: 0 20px;
      padding: 10px 15px;
    }
  }
`
