import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { LogoWrapper } from './logo.styled'

const Logo = props => {
  return (
    <LogoWrapper {...props}>
      <StaticImage
        src="../../images/transwell_logo_spzoo.png"
        imgStyle={{ objectFit: 'contain' }}
        alt="logo transwell"
      />
    </LogoWrapper>
  )
}

export default Logo
