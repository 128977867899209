import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-left: auto;
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  ul {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateX(0)' : 'translateX(100%)'};

    transition: all 0.35s ease-out;
    li {
      margin-bottom: 25px;
    }
  }
`

export const LinkMain = styled.button`
  display: flex;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: bold;
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.blue};
  align-items: center;

  span {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: ${({ theme }) => theme.colors.blue} transparent transparent
      transparent;
  }
`

export const ExtendedMenuButton = styled.button`
  margin-bottom: 25px;
  display: flex;
  align-items: center;

  p {
    text-transform: uppercase;
    font-size: 26px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }
  span {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: ${({ theme }) => theme.colors.blue} transparent transparent
      transparent;
  }
`

export const ExtendedOfferMenu = styled.div`
  max-height: ${({ isExtendedMenuOpen }) => (isExtendedMenuOpen ? `125px` : 0)};
  overflow: hidden;
  transition: all 0.25s linear;
  margin: -15px 0 15px;
  a {
    display: block;
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 5px;
    padding: 5px 0;
    font-weight: 600;
  }
  .active-link {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`
