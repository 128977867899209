export const theme = {
  colors: {
    black: '#000',
    white: '#fff',
    blue: '#009ee3',
    darkBlue: '#3c3c3c',
    lightGray: '#d8d8d8',
    mediumGray: '#aaa',
    darkGray: '#5e5e5e',
    sectionGray: '#e2e2e2',
    description: '#0c0c0c',
  },

  fonts: {
    sansSerif: "'Poppins', sans-serif",
  },
}
