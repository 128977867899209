import styled from 'styled-components'

export const LogoWrapper = styled.div`
  width: 180px;
  @media only screen and (max-width: 1023px) {
    width: 140px;
  }

  .gatsby-image-wrapper {
    position: relative;
  }
  div {
    background-color: transparent !important;
  }
`
