import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FooterWrapper } from './styles'
import SectionTitle from 'src/components/_Common/SectionTitle'
import { theme } from 'src/theme/theme'

const Footer = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "transwell_footer.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 70
              webpOptions: { quality: 70 }
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  return (
    <FooterWrapper>
      <div className="image-wrapper">
        <GatsbyImage image={image} alt="Auto Transwell" />
      </div>
      <div className="content">
        <SectionTitle color={theme.colors.white}>Kontakt</SectionTitle>
        <div className="block">
          <p>Adres korespondencyjny</p>
          <p>TRANSWELL TRANSPORT DEDYKOWANY SP. Z O.O.</p>
          <p>ul. Szeroki Łan 14</p>
          <p>41-200 Sosnowiec</p>
        </div>
        <div className="block">
          <p>NIP: PL6443572141</p>
          <p>REGON: 524270604</p>
          <p>Telefon: +48 664 956 691</p>
          <p>E-mail: biuro@transwell.pl</p>
        </div>
        <div className="divider" />
        <div className="bottom">
          © {new Date().getFullYear()} Transwell |{' '}
          <Link to="/polityka-prywatności">Polityka prywatności</Link> |{' '}
          <Link to="/cookies">Cookies</Link>
          <div className="icons">
            Icons made by{' '}
            <a href="https://www.freepik.com" title="Freepik">
              Freepik
            </a>{' '}
            from{' '}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer
