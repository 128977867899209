import styled from 'styled-components'

export const H2 = styled.h2`
  width: 100%;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme, color }) => color || theme.colors.blue};
  letter-spacing: 1px;
  margin-bottom: 40px;
  @media only screen and (max-width: 1023px) {
    font-size: 28px;
  }
`
