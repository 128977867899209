import React, { useState, useRef, useEffect } from 'react'
import * as Styled from './styles'
const useOutsideAlerter = (ref, setIsOpen, isOpen) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        console.log('zamknij')
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, isOpen, setIsOpen])
}

const Dropdown = ({ header, content, headerStyles }) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setIsOpen, isOpen)

  const shouldUpdateHover = !Object.keys(headerStyles).length

  return (
    <Styled.Wrapper ref={wrapperRef} style={headerStyles}>
      <Styled.Button
        onClick={() => setIsOpen(prevState => !prevState)}
        shouldUpdateHover={shouldUpdateHover}
      >
        {header} <span />
      </Styled.Button>
      <Styled.Ul isOpen={isOpen} shouldUpdateHover={shouldUpdateHover}>
        {content}
      </Styled.Ul>
    </Styled.Wrapper>
  )
}

export default Dropdown
