import styled from 'styled-components'

export const Button = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  transition: all 0.25s linear;
  display: none;
  opacity: 0;
  z-index: 999;
  &:hover {
    box-shadow: 0px 0px 10px -3px ${({ theme }) => theme.colors.black};
  }
  path {
    color: ${({ theme }) => theme.colors.white};
  }
`
