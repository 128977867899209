import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`
export const Button = styled.button`
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  :hover {
    color: ${({ theme, shouldUpdateHover }) =>
      shouldUpdateHover ? theme.colors.blue : theme.colors.white};
  }
  span {
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: ${({ theme }) => theme.colors.white} transparent transparent
      transparent;
  }
`

export const Ul = styled.ul`
  /* display: ${({ isOpen }) => (isOpen ? 'none' : '')}; */
  top: ${({ shouldUpdateHover }) => (shouldUpdateHover ? '44px ' : '75px')};
  left: 0;
  position: absolute;
  padding: 10px;
  background: #171717;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0)' : 'translateY(-10px)'};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transition: all 0.2s linear;
`
