module.exports = {
  siteTitle: 'TRANSWELL TRANSPORT DEDYKOWANY SP. Z O.O.', // Navigation and Site Title
  siteTitleAlt:
    'Firma transportowa, przewoźnik - Katowice | Transport Dedykowany | Transwell', // Alternative Site title for SEO
  siteTitleShort: 'transwell', // short_name for manifest
  siteUrl: 'https://transwell.pl', // Domain of your site. No trailing slash!
  lang: 'pl', // Language Tag on <html> element
  pathPrefix: '/',
  siteLogo: 'images/transwell_logo_spzoo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    'Transwell to firma wyspecjalizowana w transporcie dedykowanym rzeczy i towarów. Zajmujemy się głównie realizacją przewozów o wysokim priorytecie.',
  ogLanguage: 'pl_PL',

  // Manifest and Progress color
  themeColor: '#009ee3',
  backgroundColor: '#5e5e5e',
}
